// .../inventories/new

// sets the correct quantity in the "Quantité calculée" field for a specific stock-component select
function setCurrentQuantity(componentSelect) {
  var currentStockByComponentId = $('#new_inventory').data('current-stock-by-component-id');
  var componentId = $(componentSelect).val();

  if (componentId && currentStockByComponentId) {
    var currentQuantity = currentStockByComponentId[componentId.toString()];
    $(componentSelect)
      .closest('.fields')
      .find('input[name*=quantity][disabled]')
      .val(currentQuantity || '0');
  }
}

$(document).ready(function () {
  // prevents submitting the form when using the "enter" key because it can be pressed by mistake
  $(document).on('keyup keypress', '#new_inventory', function (e) {
    keyCode = e.keyCode || e.which;
    if (keyCode == 13) {
      e.preventDefault();
      return false;
    }
  });

  // removes all nested fields in the form when clicking on the "Retirer toutes les entrées" button
  $(document).on('click', '#new_inventory .remove_all_nested_fields', function () {
    var els = document.querySelectorAll(
      '.remove_nested_fields[data-association="component_inventories"]'
    );
    for (var i = 0; i < els.length; i++) {
      els[i].click();
    }
  });

  // triggers when selecting a stock-component
  $(document).on('change', '#new_inventory select[name*=stock_component_id]', function (e) {
    setCurrentQuantity(e.target);
  });

  // triggers when a new stock-component select field is inserted
  $('body').on('DOMNodeInserted', '#new_inventory select.watch-dom-insertion', function (e) {
    setCurrentQuantity(e.target);
  });

  // no idea wtf this is about
  // if ($('#new_inventory').length === 0) {
  //   return;
  // }
});
