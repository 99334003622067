function toggleDay(e, calendarId) {
  $.ajax({
    type: 'PATCH',
    url: '/api/calendars/' + calendarId + '/business_days/' + e.date.toDateString(),
    beforeSend: function (jqXHR, settings) {
      jqXHR.setRequestHeader('X-CSRF-Token', $("meta[name='csrf-token']").attr('content'));
    },
    success: function (response) {
      $(e.element).prop('style').removeProperty('background-color');
      $(e.element).toggleClass('day-start');
    },
    error: function (jqXHR, status, err) {
      alert(jqXHR.responseJSON.error.user_message);
    },
  });
}

$(document).ready(function () {
  var liveCalendar = document.getElementById('live-calendar');
  if (document.body.contains(liveCalendar)) {
    calendarId = liveCalendar.dataset.calendarid;

    const calendar = new Calendar('#live-calendar', {
      language: 'fr',
      style: 'background',
      dataSource: function (year_object) {
        return fetch(
          '/api/calendars/' + calendarId + '/business_days.json?year=' + year_object.year
        )
          .then((result) => result.json())
          .then((result) => {
            return result.map((r) => ({
              startDate: new Date(r.year, r.month - 1, r.day),
              endDate: new Date(r.year, r.month - 1, r.day),
              color: r.color,
            }));

            return [];
          });
      },
      clickDay: function (e) {
        toggleDay(e, calendarId);
      },
    });

    // start all weeks by monday
    calendar.setWeekStart(1);
  }
});
