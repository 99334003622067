import moment from 'moment';
import 'moment/locale/fr';

export { triggerDateTimeRangePicker };

function triggerDateTimeRangePicker(input) {
  var dropsDirection = input.attr('data-drops-direction') || 'down';

  input
    .daterangepicker({
      drops: dropsDirection,
      autoUpdateInput: false,
      locale: {
        format: 'DD/MM/YYYY HH:mm:ss',
      },
      timePicker: true,
      timePicker24Hour: true,
      timePickerIncrement: 30,
      ranges: {
        "Aujourd'hui": [moment(), moment()],
        Hier: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        '7 derniers jours': [moment().subtract(6, 'days'), moment()],
        '30 derniers jours': [moment().subtract(29, 'days'), moment()],
        'Ce mois-ci': [moment().startOf('month'), moment().endOf('month')],
        'Le mois dernier': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
      },
    })
    .on('apply.daterangepicker', function (e, picker) {
      picker.element.val(
        picker.startDate.format(picker.locale.format) +
          ' - ' +
          picker.endDate.format(picker.locale.format)
      );
    });
}
