import moment from 'moment';
import 'daterangepicker';

import { triggerDatePicker } from './date_picker.js';
import { triggerDateRangePicker } from './daterange_picker.js';
import { triggerDateTimePicker } from './datetime_picker.js';
import { triggerDateTimeRangePicker } from './datetimerange_picker.js';

$(document).ready(function () {
  triggerDatePicker($('input.date-picker'));
  triggerDateRangePicker($('input.daterange-picker'));
  triggerDateTimePicker($('input.datetime-picker'));
  triggerDateTimeRangePicker($('input.datetimerange-picker'));

  $(document).on('click', 'a.trigger-date-picker', function (e) {
    triggerDatePicker($('input.date-picker'));
  });
});
