// These methods allow to manipulate the DOM of the following pages :
//  - /stock_inputs/new
//  - /stock_outputs/new
// to use factory's scan

$(document).ready(function () {
  insertComponentFieldOnBarcodeScan();
  removeNestedFields();
});

$(document).on('nested:fieldAdded', function (e) {
  selectCorrectComponent(e.target);
});

function selectCorrectComponent(insertedFieldsDiv) {
  inputComponentOptionId = document.getElementById('scanned-component-option-id');
  // we stop if the inputComponentOptionId field is empty
  if (
    inputComponentOptionId == null ||
    inputComponentOptionId.value == '' ||
    inputComponentOptionId.value == null
  ) {
    return;
  }

  componentSelect = insertedFieldsDiv.querySelectorAll('select.selectpicker')[0];
  scanBarcodeInput = document.getElementById('stock_component_barcode');

  // set the correct option on the newly inserted select
  $(componentSelect).selectpicker('val', inputComponentOptionId.value);

  // reset the component option id in the DOM
  inputComponentOptionId.value = '';

  // re-enable the scan barcode input
  scanBarcodeInput.disabled = false;
}

function insertComponentFieldOnBarcodeScan() {
  addStockComponentBtn = document.getElementById('add_stock_component_btn');
  scanBarcodeInput = document.getElementById('stock_component_barcode');

  // we stop here if we're not on the correct page
  if (scanBarcodeInput == null) {
    return;
  }

  const regex = /^SC[0-9]*-[0-9]{1}$/i;
  scanBarcodeInput.addEventListener(
    'keyup',
    delay(function (e) {
      if (scanBarcodeInput.value.match(regex) != null) {
        // if scanned barcode matches the regex we fetch the corresponding stock-component data
        fetchStockComponentData(scanBarcodeInput.value).then(function (data) {
          if (parseInt(data.code) == 200) {
            // empty and disable the scan input
            scanBarcodeInput.value = '';
            scanBarcodeInput.disabled = true;

            // store in the DOM the component-id to later select the option the new select
            document.getElementById('scanned-component-option-id').value = data.response.id;

            // trigger the addition of a new stock-component line
            addStockComponentBtn.click();
          }
        });
      }
    }, 100)
  );
}

// Copied from https://stackoverflow.com/questions/1909441/how-to-delay-the-keyup-handler-until-the-user-stops-typing#answer-1909508
// this function is used to prevent multiple keyUp event to trigger at the same time line 19
// it delays the check of the event by the "ms" variable value
function delay(fn, ms) {
  let timer = 0;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(fn.bind(this, ...args), ms || 0);
  };
}

const removeNestedFields = () => {
  // nested_form link_to_remove just hides the field using "display: none"
  // this removes it completely
  $('.add_nested_fields').click(function () {
    setTimeout(function () {
      let removeNestedFieldsBtn = document.getElementsByClassName('remove_nested_fields');
      for (var i = 0; i < removeNestedFieldsBtn.length; i++) {
        let removeBtn = removeNestedFieldsBtn[i];
        removeBtn.addEventListener('click', function (e) {
          removeBtn.closest('.fields').remove();
        });
      }
    }, 500);
  });
};

// Fetch data from barcode_search_json_controller
const fetchStockComponentData = (stock_component_barcode) => {
  return fetch(`/stock_components_search?barcode=${stock_component_barcode}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then(function (res) {
    return res.json();
  });
};
