function toggleSelectableTr(tr_element, event) {
  event.stopPropagation();
  var checkbox = tr_element.find(
    'input[type="checkbox"][name="print_unit_barcodes[]"]:not(:disabled)'
  );
  checkbox.prop('checked', !checkbox.prop('checked'));
  tr_element.toggleClass('table-success');
}

$(document).ready(function () {
  $(document).on('click', 'tr.clickable', function (event) {
    toggleSelectableTr($(this), event);
  });
});
