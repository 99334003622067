$(document).ready(function () {
  $(document).on('change', 'input[type="checkbox"].invert-ransack', function (e) {
    let name = e.target.checked == true ? this.dataset.invertName : this.dataset.originName;
    target_input = $(e.target.dataset.targetId);
    target_input.attr('name', name);
    if (target_input.is(':checkbox')) {
      target_input.prop('checked', e.target.checked);
    }
  });

  $(document).on('change', 'input[type="radio"].due_date_behavior', function (e) {
    target_input = $(e.target.dataset.targetId);
    if (e.target.value == 'precise') {
      target_input.attr('name', 'q[due_date_eq]');
    } else if (e.target.value == 'deadline') {
      target_input.attr('name', 'q[due_date_lteq]');
    } else {
      console.log('Unknown radio-button value: ' + e.target.value);
    }
  });
});
