window.ransackHelper = {};

ransackHelper.updateProductSelect = (productionCenterId) => {
  if ($('#q_product_id_in').length) {
    var productSelect = $('#q_product_id_in');

    params = { production_center_id: productionCenterId };

    // scope the products per paper_name if a value is selected
    if ($('#q_paper_name_eq').length) {
      var paperNameSelect = $('#q_paper_name_eq');
      params['paper_name'] = paperNameSelect.val();
    }

    updateProductList(productSelect, params);
  }
};

ransackHelper.updatePaperNameSelect = (productionCenterId) => {
  if ($('#q_paper_name_eq').length) {
    var paperNameSelect = $('#q_paper_name_eq');
    updatePaperNameList(paperNameSelect, productionCenterId);
    paperNameSelect.val('');
  }
};

ransackHelper.updateAddonValueSelect = (productionCenterId) => {
  if ($('#q_product_addon_value_id_in').length) {
    let addonValueSelect = $('#q_product_addon_value_id_in');
    params = { production_center_id: productionCenterId };
    updateAddonValueList(addonValueSelect, params);
  }
};

function updateAddonValueList(addonValueSelect, params) {
  var params = new URLSearchParams(params);
  var url = '/product_addon_values?' + params.toString();

  $.getJSON(url, function (data) {
    addonValueSelect.html("<option value=''>Tous</option>");
    $.each(data, function (index, value) {
      addonValueSelect.append('<option value=' + value.id + '>' + value.name + '</option>');
    });
  });
}

function updatePaperNameList(paperNameSelect, productionCenterId) {
  var url = '/production_centers/' + productionCenterId + '/paper_names';

  $.getJSON(url, function (data) {
    paperNameSelect.html("<option value=''>Tous</option>");
    $.each(data, function (index, value) {
      paperNameSelect.append('<option value=' + value + '>' + value + '</option>');
    });
  });
}

function updateProductList(productSelect, params) {
  var params = new URLSearchParams(params);
  var url = '/products?' + params.toString();

  $.getJSON(url, function (data) {
    productSelect.html("<option value=''>Tous</option>");
    $.each(data, function (index, value) {
      productSelect.append('<option value=' + value.id + '>' + value.name + '</option>');
    });
  });
}
