export { triggerDateTimePicker };

function triggerDateTimePicker(input) {
  input
    .daterangepicker({
      autoUpdateInput: false,
      singleDatePicker: true,
      timePicker24Hour: true,
      timePicker: true,
      locale: { format: 'DD/MM/YYYY - HH:mm' },
    })
    .on('apply.daterangepicker', function (e, picker) {
      picker.element.val(picker.startDate.format(picker.locale.format));
    });
}
