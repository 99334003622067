import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

import Rails from '@rails/ujs';
Rails.start();

require('jquery');
require('popper.js');
import 'jquery_nested_form';
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';
require('bootstrap-select');
import Calendar from 'js-year-calendar';
import 'js-year-calendar/dist/js-year-calendar.css';
import 'js-year-calendar/locales/js-year-calendar.fr';

import 'javascripts/datepickers/index.js';
import 'javascripts/selectable_tr.js';
import 'javascripts/invert_ransack_filters.js';
import 'javascripts/year_calendar.js';
import 'javascripts/inventories.js';
import 'javascripts/stock_input_output_barcode_scanner.js';
import 'javascripts/quick_add_article_during_expedition.js';
import 'javascripts/ransack_improvments.js';

$(document).ready(function () {
  // trigger tooltips
  $('[data-toggle="tooltip"]').tooltip();

  // trigger bootstrap-selectpickers on page load and when a new nested field is added in a form
  $('.selectpicker').selectpicker();
  $(document).on('click', 'a.add_nested_fields', function () {
    $('.selectpicker').selectpicker();
  });
});
